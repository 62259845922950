import React, { Component } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { Badge, Collapse, TableBody, TableCell, TableContainer } from '@mui/material';
const Web3 = require('web3')
class CharactersApp extends Component {

    constructor(props) {
        super(props)
        this.state = {
        };

        this.handleExpand = this.handleExpand.bind(this);
        this.sell = this.sell.bind(this);

        let cardState = [];

        this.props.characterData.map((i, _) => {
            cardState.push({ expanded: false });
        })
        this.state.cardState = cardState;


    }
    componentDidMount() {
        console.log('mounting');
    }

    sell(tokenId) {
        let url = 'https://testnets.opensea.io/assets/' + this.props.contractAddress + '/' + tokenId;
        window.open(url, "_blank") //to open new page
    }

    handleExpand(idx) {
        let l = this.state.cardState;
        l[idx].expanded = !l[idx].expanded;
        this.setState({ cardState: l });
    }
    render() {
        const ExpandMore = styled((props) => {
            const { expand, ...other } = props;
            return <IconButton {...other} />;
        })(({ theme, expand }) => ({
            transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        }));

        console.log('cd',this.props.characterData)
        return (
            <Box sx={{ flexGrow: 1 }}>

                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" >
                    {this.props.characterData.map((cd, i) =>
                        <Grid item key={i} xs={4} sm={4} md={6}>
                            <Card sx={{ maxWidth: 250 }}>
                                <CardHeader
                                    avatar={
                                        <Badge badgeContent={cd.attributes.length} color="primary">
                                            <Avatar alt='' src={cd.avatarimage} />

                                        </Badge>
                                    }
                                    title={cd.name}
                                />
                                <CardContent>
                                    <CardMedia
                                        component="img"
                                        image={cd.thumbimage}
                                        alt=""
                                    />
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {cd.description}
                                    </Typography>
                                </CardContent>
                                <CardActions disableSpacing>
                                    <IconButton aria-label="add to favorites">
                                        <FavoriteIcon />
                                    </IconButton>
                                    <IconButton aria-label="Download" href={cd.fullimage} target="_blank" download>
                                        <DownloadIcon />
                                    </IconButton>
                                    <IconButton aria-label="share" onClick={() => this.sell(cd.tokenId)}>
                                        <AttachMoneyIcon />
                                    </IconButton>
                                    <ExpandMore
                                        expand={this.state.cardState[i].expanded}
                                        onClick={() => this.handleExpand(i)}
                                        aria-expanded={this.state.cardState[i].expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </CardActions>
                                <Collapse in={this.state.cardState[i].expanded} timeout={"auto"} unmountOnExit>
                                    <Typography gutterBottom component="div">
                                        Attributes
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 100 }} >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cd.attributes.map((a, j) => {
                                                    return <TableRow key={j}>
                                                        <TableCell>{a.trait_type}</TableCell>
                                                        <TableCell>{a.value}</TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Collapse>
                            </Card>
                        </Grid>
                    )
                    }
                </Grid>
            </Box >
        )
    }
}

export default CharactersApp