import React, { Component } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import NpcHubPng from './images/npchub-icon.png'
import { Link } from '@mui/material';
import { DialogContent, Button, Dialog, DialogActions, DialogContentText, DialogTitle } from '@mui/material';

class Title extends Component {

    constructor(props) {
        super(props)
        this.state = {
            disclaimerDialogOpen:false,
        };


        this.handleDisclaimerDialogClose = this.handleDisclaimerDialogClose.bind(this);
        this.handleDisclaimerDialogOpen = this.handleDisclaimerDialogOpen.bind(this);
    }


    handleDisclaimerDialogClose(m) {
        this.setState({ disclaimerDialogOpen: false });
    }
    handleDisclaimerDialogOpen(m) {
        this.setState({ disclaimerDialogOpen: true });
    }

    componentDidMount() {
        console.log('mounting');
    }

    render() {
        console.log('rendering');

        return (
            <Box sx={{ flexGrow: 1 }} >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" align="center" justify="center" >
                    <Grid item xs={4} sm={8} md={12}  >
                        <Paper variant="outlined">
                            <img src={NpcHubPng} height="230" width="230" alt='NpcHub' />
                        </Paper>
                    </Grid>
                    <Grid item xs={4} sm={8} md={12}  >
                        <Link component="button"
                            variant="body2"
                            onClick={this.props.onConnectWallet}
                        >
                            Connect Wallet
                        </Link>
                    </Grid>
                    <Grid item xs={4} sm={8} md={12}  >
                        <iframe width="360" height="315" src="https://www.youtube.com/embed/e0eZAID4kp8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </Grid>
                    <Grid item xs={4} sm={8} md={12}  >
                        <Link component="button"
                            variant="body2"
                            onClick={this.handleDisclaimerDialogOpen}
                        >
                           disclaimer 
                        </Link>
                    </Grid>
                </Grid>
                <Dialog open={this.state.disclaimerDialogOpen} onClose={this.handleDisclaimerDialogClose}>
                    <DialogTitle>NpcHub Disclaimer</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            All minted NFTs will be transfered to the wallet used to mint the NFT.  Never upload any personally identifiable information.  A small service charge will be added.  A royalty will be charged in the event the NFT is sold.  Uploaded images may be used to improve the tool and user experience. 

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDisclaimerDialogClose}>Close</Button>
                    </DialogActions>
                </Dialog>

            </Box>
        )
    }
}

export default Title
