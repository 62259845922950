
import React, { Component } from 'react';

import axios from 'axios'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Jcrop from 'jcrop';
import 'jcrop/dist/jcrop.css'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import NpcHubPng from './images/npchub-icon.png'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ContentCut from '@mui/icons-material/ContentCut';
import UndoIcon from '@mui/icons-material/Undo';
import AutoFixNormal from '@mui/icons-material/AutoFixNormal';
import BrightnessLow from '@mui/icons-material/BrightnessLow';
import CreateIcon from '@mui/icons-material/Create';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import BorderOuter from '@mui/icons-material/BorderOuter';
import Tooltip from '@mui/material/Tooltip';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import Brush from '@mui/icons-material/Brush';
import Crop from '@mui/icons-material/Crop';
import { LinearProgress } from '@mui/material';

class AssetSelect extends Component {

    constructor(props) {
        super(props)
        this.state = {
            assetURI: null,
            assetPages: [],
        };


        this.handleSetNumPages = this.handleSetNumPages.bind(this);
        this.onTargetLoaded = this.onTargetLoaded.bind(this);
        this.popImg = this.popImg.bind(this);
        this.styleImg = this.styleImg.bind(this);
        this.cropImg = this.cropImg.bind(this);
        this.setPageSrc = this.setPageSrc.bind(this);
        this.fileSelect = this.fileSelect.bind(this);
        this.currentPageId = null;

        this.state.assetPages.push({
            id: 'page-0',
            src: NpcHubPng,
            busy: false
        });
    }

    handleSetNumPages(e) {
        console.log('pages ', e);
        let lpages = [];
        Array(e.target.value).fill(0).map((_, i) => {
            lpages.push({
                id: 'page-' + i,
                src: NpcHubPng,
                busy: false
            });
        }
        );
        this.setState({ assetPages: lpages })
    }

    newImg(e) {
        console.log('bws', e);
        this.currentPageId = e;
        var r = document.getElementById('fileinput').click();
        console.log('results',r)
    }

    onTargetLoaded({ target: img }, lpageID) {

        this.state.assetPages.forEach(_n => {
            if (_n.id === lpageID) {
                _n.assetXSize = img.offsetWidth;
                _n.assetYSize = img.offsetHeight;
            }
        })
        console.log('asset state', this.state.assetPages)
    }

    isTargetLoaded(pageID) {

        console.log('checking pageID', pageID)
        let found = false;
        this.state.assetPages.forEach(_n => {
            if (_n.id === pageID && _n.loaded) {
                found = true;
            }
        });
        console.log('ret', found)
        return found;
    }


    popImg(e) {
        this.setPageBusy(e, true);
        axios.delete(this.props.img_ip + '/stage', {
            headers: {
                'x-access-token': this.props.token,
            },
            params: {
                productID: this.props.productID,
                pageID: e,
            }
        })
            .then(response => {
                let imgUri = this.props.img_ip + response.data.assetURI;
                this.setPageSrc(e, imgUri, true);
            }).catch(error => {
                //TODO RED X
                this.setPageSrc(e, NpcHubPng, false);
                this.props.onErrorMsg('Pop failure');
            }).finally(() => { this.setPageBusy(e, false) });
    }

    styleImg(pageID, action) {
        this.setPageBusy(pageID, true);
        axios.put(this.props.img_ip + '/stage', null, {
            headers: {
                'x-access-token': this.props.token,
            },
            params: {
                productID: this.props.productID,
                pageID: pageID,
                action: action,
            }
        })
            .then(response => {
                console.log('response', response)
                let imgUri = this.props.img_ip + response.data.assetURI;
                this.setPageSrc(pageID, imgUri, true);
            }).catch(error => {
                this.props.onErrorMsg('shadow failure');
                if (error.response.status === 429) {
                    this.props.onErrorMsg('Too many requests');
                }
                else {
                    this.setPageSrc(pageID, NpcHubPng, false)

                }
            }).finally(() => { this.setPageBusy(pageID, false) });
    }

    encode_boxes(ljcrop) {
        let boxes_enc = []

        ljcrop.crops.forEach(c => {
            let xmin = c.pos.x;
            let ymin = c.pos.y;
            let xmax = xmin + c.pos.w;
            let ymax = ymin + c.pos.h;
            let _class = c.options.c ? 'c' in c.options : -1;
            boxes_enc.push({
                c: _class,
                box: [xmin, ymin, xmax, ymax]
            });
        });
        return JSON.stringify(boxes_enc);
    }

    cropImg(e) {
        this.setPageBusy(e, true);

        this.state.assetPages.forEach(_n => {
            if (_n.id === e) {
                if (typeof _n.jcrop === 'undefined') {

                    _n.jcrop = Jcrop.attach(_n.id + 'target', {
                        canRemove: true,
                        multiMin: -1,
                        multi: false,
                    });
                    let rect = Jcrop.Rect.fromPoints([0, 0], [100, 100]);
                    _n.jcrop.newWidget(rect);
                    this.setPageBusy(e, false);
                }
                else {   //crop

                    axios.get(this.props.img_ip + '/crop', {
                        headers: {
                            'x-access-token': this.props.token,
                        },
                        params: {
                            productID: this.props.productID,
                            pageID: e,
                            imgHeight: _n.assetYSize,
                            imgWidth: _n.assetXSize,
                            boxes_encoded: this.encode_boxes(_n.jcrop)
                        }
                    })
                        .then(response => {
                            console.log(response);
                            _n.src = this.props.img_ip + response.data.assetURI + '?' + Date.now();
                        }).catch(error => {
                            this.props.onErrorMsg('Error' + error, "error")
                            this.setState({ busy: false });
                        }).finally(() => {
                            this.setPageBusy(e, false);
                            _n.jcrop.crops.forEach(c => {
                                _n.jcrop.removeWidget(c);
                            })
                            _n.jcrop.setEnabled(false);
                            delete _n.jcrop;
                        });
                }
            }
        });
    }

    setPageBusy(pageID, busy) {
        let lpages = this.state.assetPages;
        lpages.forEach(_n => {
            if (_n.id === pageID) {
                _n.busy = busy;
            }
        });
        this.setState({
            assetPages: lpages
        });
        console.log('pages', this.state.assetPages);
    }

    setPageSrc(pageID, uri, loaded) {
        let lpages = this.state.assetPages;
        lpages.forEach(_n => {
            console.log('checking', _n.id, pageID)
            if (_n.id === pageID) {
                _n.src = uri + '?' + Date.now();
                _n.loaded = loaded;
            }
        });
        this.setState({
            assetPages: lpages
        });
        console.log('pages', this.state.assetPages);
    }

    fileUpload(file) {
        const url = this.props.img_ip + '/stage';
        const formData = new FormData();
        formData.append('file', file)
        console.log('file', file);
        const config = {
            headers: {
                'x-access-token': this.props.token,
                'content-type': 'multipart/form-data'
            },
            params: {
                productID: this.props.productID,
                pageID: this.currentPageId,
            }

        }
        return axios.post(url, formData, config);

    }


    fileSelect(e) {
        this.setPageBusy(this.currentPageId, true);
        console.log('select file', e);
        let selectedFile = e.target.files[0];

        this.fileUpload(selectedFile).then((response) => {
            console.log('upload resp', response.data);
            let imgUri = this.props.img_ip + response.data.assetURI;
            this.setPageSrc(response.data.pageID, imgUri, true);
            this.props.onPageSet(response.data.pageID);

        }).catch(error => {
            console.log(error);
            this.props.onErrorMsg('unable to select');
            if (error.response) {
                if (error.response.status === 413) {
                    this.props.onErrorMsg('Upload too big!');
                }
            }
        }).finally(() => { this.setPageBusy(this.currentPageId, false) });


    }

    componentDidMount() {
        console.log('mounting');
        if (this.props.productID && this.props.pageIDs) {
            this.props.pageIDs.forEach(pageID => {
                console.log('checking', pageID)

                axios.get(this.props.img_ip + '/producturi/latest/' + this.props.productID + '/' + pageID + '/stage', {
                    headers: {
                        'x-access-token': this.props.token,
                    },
                    params: {
                    }
                })
                    .then(response => {
                        let imgUri = this.props.img_ip + response.data.assetURI;
                        this.state.assetPages.push({ id: pageID, src: imgUri, busy: false, jcrop: null })
                    }).catch(error => {
                        this.state.assetPages.push({ id: pageID, src: NpcHubPng, busy: false, jcrop: null })
                    }).finally(() => this.setState({}));
            });
        }
    }

    render() {
        console.log('rendering');
        return (
            <Box sx={{ flexGrow: 1 }}>

                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" >
                    <Grid item xs={4} sm={8} md={12}  >
                        <FormControl sx={{ maxWidth: 345, minWidth: 345 }}>
                            <InputLabel id="page-select-label">Number of Sheets</InputLabel>
                            <Select
                                labelId="page-select-label"
                                id="page-select"
                                value={this.state.assetPages.length}
                                label="Num Pages"
                                onChange={this.handleSetNumPages}
                            >
                                {Array(4).fill(0).map((_, i) =>
                                    <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    {this.state.assetPages.map((page, i) =>
                        <Grid key={i} item xs={4} sm={4} md={6}  >
                            <Card sx={{ maxWidth: 400 }} style={{ backgroundColor: 'transparent', width: 400 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {'Page - ' + i}
                                    </Typography>
                                    <CardMedia
                                        component="img"
                                        image={page.src}
                                        onClick={() => this.newImg(page.id)}
                                        onLoad={(e) => this.onTargetLoaded(e, page.id)}
                                        id={page.id + 'target'}
                                        style={{ position: "relative" }}
                                    />
                                    <LinearProgress value={100} variant={page.busy ? 'indeterminate' : 'determinate'} />
                                </CardContent>
                                <CardActions >
                                    <Tooltip title="Upload">
                                        <IconButton id={page.id} disabled={this.props.productID === null} onClick={() => this.newImg(page.id)}>
                                            <FileUploadIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Auto-crop">
                                        <IconButton id={page.id} disabled={!this.isTargetLoaded(page.id)} onClick={() => this.styleImg(page.id, "autocrop")}>
                                            <ContentCut />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Binary Threshold">
                                        <IconButton id={page.id} disabled={!this.isTargetLoaded(page.id)} onClick={() => this.styleImg(page.id, "threshold")}>
                                            <AutoFixNormal />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Sharpen">
                                        <IconButton id={page.id} disabled={!this.isTargetLoaded(page.id)} onClick={() => this.styleImg(page.id, "sharpen")}>
                                            <InsertPhotoIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Manual-crop">
                                        <IconButton id={page.id} disabled={!this.isTargetLoaded(page.id)} onClick={() => this.cropImg(page.id)}>
                                            <Crop />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Undo">
                                        <IconButton id={page.id} disabled={!this.isTargetLoaded(page.id)} onClick={() => this.popImg(page.id)}>
                                            <UndoIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Oil">
                                        <IconButton id={page.id} disabled onClick={() => this.styleImg(page.id, "oil")}>
                                            <Brush />
                                        </IconButton>
                                    </Tooltip>
                                </CardActions>
                                <CardActions>
                                    <Tooltip title="Sketch">
                                        <IconButton id={page.id} disabled onClick={() => this.styleImg(page.id, "pencil")}>
                                            <CreateIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Stylize">
                                        <IconButton id={page.id} disabled onClick={() => this.styleImg(page.id, "stylize")}>
                                            <FormatColorFillIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Brighten">
                                        <IconButton id={page.id} disabled={!this.isTargetLoaded(page.id)} onClick={() => this.styleImg(page.id, "brighten")}>
                                            <BrightnessLow />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Shadow">
                                        <IconButton id={page.id} disabled={!this.isTargetLoaded(page.id)} onClick={() => this.styleImg(page.id, "shadow")}>
                                            <MonochromePhotosIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Border">
                                        <IconButton id={page.id} disabled={!this.isTargetLoaded(page.id)} onClick={() => this.styleImg(page.id, "border")}>
                                            <BorderOuter />
                                        </IconButton>
                                    </Tooltip>


                                </CardActions>
                            </Card>
                        </Grid>
                    )}

                </Grid>
                <div>
                <input hidden id='fileinput' type='file' onChange={this.fileSelect} accept="image/*"/>

                </div>

            </Box>
        )
    }
}

export default AssetSelect