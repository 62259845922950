
import React, { Component } from 'react';
import ReactGA, { exception } from 'react-ga'


import axios from 'axios'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import AppBar from '@mui/material/AppBar';
import AddIcon from '@mui/icons-material/Add';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AssetApp from './AssetApp';
import CharactersApp from './CharactersApp';
import HomeIcon from '@mui/icons-material/Home';
import detectEthereumProvider from '@metamask/detect-provider';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { DialogContent, Button, Dialog, DialogActions, DialogContentText, DialogTitle, TextField, Toolbar, Tooltip } from '@mui/material';
import Title from './Title';
import npc_contract from './images/npc_mint.json'

const Web3 = require('web3')

class NpcApp extends Component {

    constructor(props) {
        super(props)
        this.state = {
            account: null,
            characterData: null,
            activePane: null,
            issueDialogOpen: false,
            img_ip: 'https://img-dot-heph2-338519.uc.r.appspot.com', //TODO pass down to AssetApp
            contractAddress: '0xCBE71CA35F21a2e17f579fda93fD08b77876CA90'
        };

        this.connectWallet = this.connectWallet.bind(this);
        this.handleIssueCancel = this.handleIssueCancel.bind(this);
        this.handleIssueTextFieldChanged = this.handleIssueTextFieldChanged.bind(this);
        this.handleIssueSend = this.handleIssueSend.bind(this);
        this.handleIssueDialogClose = this.handleIssueDialogClose.bind(this);
        this.handleIssueDialogOpen = this.handleIssueDialogOpen.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.snackMsg = this.snackMsg.bind(this);
        this.handleMenuChange = this.handleMenuChange.bind(this);
        this.handleErrorMsg = this.handleErrorMsg.bind(this);
        this.handleWarnMsg = this.handleWarnMsg.bind(this);
        this.handleInfoMsg = this.handleInfoMsg.bind(this);
        this.handleMint = this.handleMint.bind(this);
        this.handleConnectWallet = this.handleConnectWallet.bind(this);

    }


    async getCharacterData(account) {
        console.log('account check', account)

        const web3 = new Web3(window.ethereum);
        var contract = new web3.eth.Contract(npc_contract.abi, this.state.contractAddress);

        let characterData = [];

        let events = await contract.getPastEvents('Transfer',
            {
                fromBlock: 0,
                toBlock: 'latest'
            });

        for (const evt of events) {
            let txn_hash = evt.transactionHash;
            let tokenId = evt.returnValues.tokenId;
            let recipientAddr = evt.returnValues.to;

            //TODO add account to event filter above
            if (recipientAddr.toLowerCase() !== account.toLowerCase()) {
                console.log('skipping', recipientAddr, account)
                continue;
            }

            var txn = await web3.eth.getTransaction(txn_hash);

            const abi = [
                {
                    type: "address",
                    name: "recipient"
                },
                {
                    type: "string",
                    name: "uri",
                }
            ];

            const decoded = web3.eth.abi.decodeParameters(abi, txn.input.slice(10));

            let meta = await axios.get(decoded.uri, {
                headers: {},
                params: {}
            });

            //TODO remove 
            if ('thumbimage' in meta.data) {
                meta.data.tokenId = tokenId;
                characterData.push(meta.data);
            }

        }

        return characterData;
    }

    snackMsg(m, s) {
        this.setState({
            snackSeverity: s,
            snackMessage: m,
            snackOpen: true
        });
    }
    handleIssueTextFieldChanged(m) {
        this.setState({ issueText: m.target.value});
    }
    handleIssueCancel(m) {
        this.setState({ issueDialogOpen: false });
    }
    handleIssueSend(m) {
        console.log('issue', this.state.issueText)

        axios.get(this.state.img_ip + '/issue', {
            params: {
                issue: this.state.issueText
            }
        }).then(response => {
            console.log(response);
            this.handleInfoMsg('Issue sent.  Thanks!');
        }).catch(error => {
            this.handleErrorMsg('Error - Unable to send issue');
        }).finally(() => {
            this.setState({ 
                issueDialogOpen: false,
                issueText :""
             });
        })

    }
    handleIssueDialogClose(m) {
        this.setState({ issueDialogOpen: false });
    }
    handleIssueDialogOpen(m) {
        this.setState({ issueDialogOpen: true });
    }
    handleMenuChange(m) {
        this.setState({ activePane: m });
    }
    handleInfoMsg(m) {
        this.snackMsg(m, "info");
    }
    handleWarnMsg(m) {
        this.snackMsg(m, "warning");
    }
    handleErrorMsg(m) {
        this.snackMsg(m, "error");
    }
    handleCloseSnackbar(e) {
        console.log('snack bar is closed');
        this.setState({ snackOpen: false });
    }

    handleMint(receipt) {

        this.getCharacterData(this.state.account).then((cd) => {
            this.setState({ activePane: 'title' });
        }).catch((e) => {

            this.handleErrorMsg('Unable to load characters');
            this.setState({ activePane: 'title' });
        });
    }

    componentDidMount() {
        console.log('mounting');

    }

    async connectWallet() {

        var provider = await detectEthereumProvider();

        if (provider == null) {
            throw exception("MetaMask not detected");
        }

        console.log('ethereum MetaMask', provider.isMetaMask)
        console.log('ethereum address', window.ethereum.selectedAddress)

        let acctRes = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log('acctReq resp', acctRes);
        this.state.account = acctRes[0];
        let chainRes = await window.ethereum.request({ method: 'eth_chainId' });
        console.log('chainId resp', chainRes);
        this.state.chainId = chainRes;

        return this.state.account;

    }

    handleConnectWallet() {

        this.connectWallet().then((account) => {
            this.handleInfoMsg("Connected to MetaMask");
            this.getCharacterData(account).then((cd) => {
                this.setState({ characterData: cd });
                console.log('got cd', cd)
            }
            );

        }).catch((e) => {
            this.handleErrorMsg("Unable to connect to MetaMask");

        })

    }
    render() {
        console.log('rendering');
        let pane = <Title onConnectWallet={this.handleConnectWallet} />;

        if (this.state.activePane === 'title') {
            pane = <Title onConnectWallet={this.handleConnectWallet} />;
        }
        else if (this.state.activePane === 'new') {
            pane = <AssetApp
                account={this.state.account}
                chainId={this.state.chainId}
                onErrorMsg={this.handleErrorMsg}
                onWarnMsg={this.handleWarnMsg}
                onInfoMsg={this.handleInfoMsg}
                onMint={this.handleMint} />
        } else if (this.state.activePane === 'character') {
            pane = <CharactersApp
                account={this.state.account}
                contractAddress={this.state.contractAddress}
                chainId={this.state.chainId}
                characterData={this.state.characterData}
                onErrorMsg={this.handleErrorMsg}
                onWarnMsg={this.handleWarnMsg}
                onInfoMsg={this.handleInfoMsg} />
        }

        return (
            <Box sx={{ flexGrow: 1 }} >
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
                            NpcHub
                        </Typography>
                        <Tooltip title="Home">
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={() => { this.handleMenuChange('title') }}>
                                < HomeIcon />
                            </IconButton>

                        </Tooltip>
                        <Tooltip title="Characters">
                            <IconButton
                                size="large"
                                color="inherit"
                                disabled={this.state.characterData === null}
                                onClick={() => { this.handleMenuChange('character') }}>
                                < PeopleAltIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="New NFT">
                            <IconButton
                                size="large"
                                color="inherit"
                                disabled={this.state.account === null}
                                onClick={() => { this.handleMenuChange('new') }}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Report Issue">
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={this.handleIssueDialogOpen}>
                                <ReportProblemIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" align="center" justify="center" >
                    <Grid item xs={4} sm={8} md={12}  >
                        {pane}
                    </Grid>
                </Grid>
                <Snackbar open={this.state.snackOpen} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
                    <MuiAlert onClose={this.handleCloseSnackbar} severity={this.state.snackSeverity} sx={{ width: '100%' }}>
                        {this.state.snackMessage}
                    </MuiAlert>
                </Snackbar>
                <Dialog open={this.state.issueDialogOpen} onClose={this.handleIssueDialogClose}>
                    <DialogTitle>Report Issue</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            What did you experience?
                        </DialogContentText>
                        <TextField autoFocus
                            onChange={this.handleIssueTextFieldChanged}
                            margin="dense"
                            id="name"
                            label="Issue"
                            type="text"
                            fullWidth
                            variant="standard" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleIssueCancel}>Cancel</Button>
                        <Button onClick={this.handleIssueSend}>Submit</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }
}

export default NpcApp