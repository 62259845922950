
import React, { Component } from 'react';

import axios from 'axios'

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CardMedia from '@mui/material/CardMedia';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExtensionIcon from '@mui/icons-material/Extension';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Jcrop from 'jcrop';
import 'jcrop/dist/jcrop.css'

//import V5 from './images/dnd-sheet1.png'
import V5 from './images/dnd-die3.png'
class ColorWidget extends Jcrop.Widget {
    init(color, c) {
        super.init();
        this.el.style.backgroundColor = color;
        this.el.style.opacity = .4;
        this.setOptions({ c: c })
    }
}
class RedWidget extends ColorWidget { init() { super.init('red', 0); } }
class OrangeWidget extends ColorWidget { init() { super.init('orange', 1); } }
class GreenWidget extends ColorWidget { init() { super.init('green', 2); } }
class YellowWidget extends ColorWidget { init() { super.init('yellow', 3); } }
class BlueWidget extends ColorWidget { init() { super.init('blue', 4); } }
class PurpleWidget extends ColorWidget { init() { super.init('purple', 5); } }
class BrownWidget extends ColorWidget { init() { super.init('brown', 6); } }
class FuchsiaWidget extends ColorWidget { init() { super.init('fuchsia', 7); } }

let widget_ctors = {
    attribute: RedWidget,
    skills: OrangeWidget,
    ac: GreenWidget,
    name: YellowWidget,
    hp: BlueWidget,
    class: PurpleWidget,
    attributes: BrownWidget,
    text: FuchsiaWidget
};



function getWidgetCtor(from_id) {
    if (from_id === 0) return widget_ctors.attribute;
    if (from_id === 1) return widget_ctors.skills;
    if (from_id === 2) return widget_ctors.ac;
    if (from_id === 3) return widget_ctors.name;
    if (from_id === 4) return widget_ctors.hp;
    if (from_id === 5) return widget_ctors.class;
    if (from_id === 6) return widget_ctors.attributes;
    if (from_id === 7) return widget_ctors.text;
}


class AssetAttrs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            assetURI: null,
            activeTab: '',
            attributes: [],
            dgColumns: [
                { field: 'class', headerName: 'Class', width: 50, editable: false, sortable: true },
                { field: 'label', headerName: 'Label', width: 175, editable: true },
                { field: 'value', headerName: 'Value', width: 175, editable: true },
                { field: 'score', headerName: 'Score', width: 175, editable: true }],
            dgModel: {},
            sheets:
                [
                    { name: 'V5', src: V5, },
                    { name: 'V4', src: V5, }
                ],
            selectedFormat: 'V5',
            pages: [],
            selectedPage: null,
            canDoClassify: false,
            canDoVision: false,
        };

        if (this.props.productID) {
            console.log('productID provided', this.props.img_ip + this.props.productID)
            this.state.productID = this.props.productID
        }
        this.doVision = this.doVision.bind(this);
        this.doVisionOnly = this.doVisionOnly.bind(this);
        this.changeWidgetType = this.changeWidgetType.bind(this);
        this.deleteActiveWidget = this.deleteActiveWidget.bind(this);
        this.doBoxes = this.doBoxes.bind(this);
        this.onTargetLoaded = this.onTargetLoaded.bind(this);
        this.handleEditRows = this.handleEditRows.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.handleSelectFormat = this.handleSelectFormat.bind(this);

        this.props.pageIDs.forEach(_n => {
            this.state.pages.push({
                id: _n,
                src: this.props.img_ip + '/product/latest/' + this.props.productID + '/' + _n + '/mint',
                text: _n,
            })

        })


    }
    detachJcrop() {
        if (!this.state.jcrop) {
            return;
        }
        console.log('detatching jcrop')
        this.state.jcrop.crops.forEach(c => {
            this.state.jcrop.removeWidget(c);
        });
        this.state.jcrop.setEnabled(false);
        delete this.state.jcrop;
        this.setState({ jcrop: null });
    }

    attachJcrop() {
        if (this.state.jcrop) {
            this.detachJcrop();
        }

        console.log('attaching jcrop');
        let l_jcrop = Jcrop.attach("croptarget", {
            canRemove: true,
            multiMin: -1,
            multi: true,
        });
        l_jcrop.setEnabled(false);
        l_jcrop.listen('crop.activate', (w, e) => {
            console.log('activate', w, e)
            if (this.state.jcrop.crops.size > 0) {
                console.log('setting');
                this.setState({ canDoVision: true });
            }
            else {

                console.log('not settting');
                this.setState({ canDoVision: false });
            }
        })
        this.setState({
            canDoClassify: true,
            jcrop: l_jcrop
        });

    }
    handleToggle(idx) {
        console.log('toggle idx', idx, this.state.pages[idx]);
        this.setState({ selectedPage: this.state.pages[idx] });

    };
    handleTabChange(idx) {
        console.log('toggle idx', idx, this.state.pages[idx]);
        this.setState({ activeTab: idx });

    };

    onTargetLoaded({ target: img }) {
        console.log('loading target')
        this.attachJcrop();
        this.setState({
            canDoClassify: true,
            assetXSize: img.offsetWidth,
            assetYSize: img.offsetHeight
        });

    }

    encode_boxes(ljcrop) {
        let boxes_enc = []

        ljcrop.crops.forEach(c => {
            let xmin = c.pos.x;
            let ymin = c.pos.y;
            let xmax = xmin + c.pos.w;
            let ymax = ymin + c.pos.h;
            let _class = c.options.c;
            boxes_enc.push({
                c: _class,
                box: [xmin, ymin, xmax, ymax]
            });
        });
        console.log('encoded boxes', boxes_enc)
        return JSON.stringify(boxes_enc);
    }

    handleSelectFormat(e) {
        this.setState({ selectedFormat: e.target.value });
    }

    deleteActiveWidget(_type) {
        console.log('change widget to', _type)

        if (this.state.jcrop === null) {
            return;
        }
        this.state.jcrop.removeWidget(this.state.jcrop.active);
    }
    changeWidgetType(_type) {
        console.log('change widget to', _type)

        if (this.state.jcrop === null) {
            return;
        }

        if (_type === 'skills') {
            this.state.jcrop.setOptions({ widgetConstructor: widget_ctors.skills, });
        }
        else if (_type === 'attribute') {
            this.state.jcrop.setOptions({ widgetConstructor: widget_ctors.attribute, });
        }
        else if (_type === 'class') {
            this.state.jcrop.setOptions({ widgetConstructor: widget_ctors.class, });
        }
        this.state.jcrop.setEnabled(true);
        this.setState()
    }

    handleEditRows(e) {
        console.log('editing', e)

        this.state.attributes.forEach((_next) => {
            console.log('checking', _next)
            if (_next.id === e.id) {
                console.log('found', _next)
                _next[e.field] = e.value;
            }
        })
        console.log('results', this.state.attributes)
        this.props.onAttributesReceived(this.state.attributes);
    }

    handleSelectionChanged(e) {
        console.log('selecting', e)
        this.props.onSelection(e);

    }
    componentDidMount() {
        console.log('mounting');

        if (this.props.attributes) {
            console.log('got attrs', this.props.attributes);
            this.setState({
                attributes: this.props.attributes,
                selectedPage: this.state.pages[0],
            });

        }
        else {
            if (!this.props.productID) {
                this.props.onWarnMsg("No asset.");
            }
        }

    }


    setPageBusy(pageID, busy) {
        let lpages = this.state.pages;
        lpages.forEach(_n => {
            if (_n.id === pageID) {
                _n.busy = busy;
            }
        });
        this.setState({
            assetPages: lpages
        });
        console.log('pages', this.state.assetPages);
    }

    reload_crops(boxes) {
        if (!this.state.jcrop) {
            return;
        }
        this.state.jcrop.crops.forEach(c => {
            this.state.jcrop.removeWidget(c);
        });

        let l_jcrop = this.state.jcrop;
        boxes.forEach(function (item, index, array) {
            let wctor = getWidgetCtor(item[0]);
            l_jcrop.setOptions({ widgetConstructor: wctor });

            console.log('item', item[0])
            let options = { c: item[0] };
            let xmin = item[1][0];
            let ymin = item[1][1];
            let xmax = item[1][2];
            let ymax = item[1][3];
            console.log('cropping ', xmin, ymin, xmax, ymax);
            let rect = Jcrop.Rect.fromPoints([xmin, ymin], [xmax, ymax]);
            l_jcrop.newWidget(rect, options);
        });

    }

    doBoxes() {
        console.log('boxes');
        this.setPageBusy(this.state.selectedPage.id, true);
        this.props.onInfoMsg("classifying");
        axios.get(this.props.odt_ip + '/boxes', {
            headers: {
                'x-access-token': this.props.token,
            },
            params: {
                productID: this.props.productID,
                pageID: this.state.selectedPage.id,
                imgHeight: this.state.assetYSize,
                imgWidth: this.state.assetXSize,
                detectThreshold: .2,
                decodeBoxes: true,
            }
        })
            .then(response => {
                console.log(response);
                this.state.jcrop.setEnabled(true);
                this.reload_crops(response.data.boxes)


            }).catch(error => {
                console.log('no boxes!', error)
                this.props.onErrorMsg('Unable to classify');
            }).finally(() => { this.setPageBusy(this.state.selectedPage.id) });
    }

    doVision() {
        console.log('vision');
        this.setPageBusy(this.state.selectedPage.id, true);
        this.props.onInfoMsg("visualizing");
        axios.get(this.props.odt_ip + '/vision', {
            headers: {
                'x-access-token': this.props.token,
            },
            params: {
                productID: this.props.productID,
                pageID: this.state.selectedPage.id,
                boxes_encoded: this.encode_boxes(this.state.jcrop),
                decodeBoxes: true,
                imgHeight: this.state.assetYSize,
                imgWidth: this.state.assetXSize,
            }
        })
            .then(response => {
                console.log('vision response', response.data);

                let rcnt = 0;
                let lattributes = [];
                for (const [_class, _label_values] of Object.entries(response.data.labels)) {
                    for (const [_, _value] of Object.entries(_label_values)) {
                        lattributes.push({
                            id: rcnt++,
                            class: _class,
                            label: _value[0],
                            value: _value[1],
                            score: _value[2],
                        });
                    }
                }
                console.log('label values', lattributes)
                this.setState({ attributes: lattributes });
                this.props.onAttributesReceived(lattributes);
            }).catch(error => {
                this.props.onErrorMsg('vision error');
            }).finally(() => { this.setPageBusy(this.state.selectedPage.id) });

    }
    doVisionOnly() {
        console.log('visionOnly');
        this.setPageBusy(this.state.selectedPage.id, true);
        this.props.onInfoMsg("visualizing");
        axios.get(this.props.odt_ip + '/vision_only', {
            headers: {
                'x-access-token': this.props.token,
            },
            params: {
                productID: this.props.productID,
                pageID: this.state.selectedPage.id,
            }
        })
            .then(response => {
                console.log('vision response', response.data);

                let rcnt = 0;
                let lattributes = [];
                for (const [_class, _label_values] of Object.entries(response.data.labels)) {
                    for (const [_, _value] of Object.entries(_label_values)) {
                        lattributes.push({
                            id: rcnt++,
                            class: _class,
                            label: _value[0],
                            value: _value[1],
                            score: _value[2],
                        });
                    }
                }
                console.log('label values', lattributes)
                this.setState({ attributes: lattributes });
                this.props.onAttributesReceived(lattributes);
            }).catch(error => {
                this.props.onErrorMsg('vision error');
            }).finally(() => { this.setPageBusy(this.state.selectedPage.id) });

    }

    render() {
        console.log('rendering', this.state.selectedPage, this.state.attributes);
        console.log('jcrop', this.state.jcrop);

        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" >
                    <Grid item xs={4} sm={8} md={12}  >
                        <FormControl sx={{ maxWidth: 345, minWidth: 345 }}>
                            <InputLabel id="page-select-label">DnD Sheet Version</InputLabel>
                            <Select
                                labelId="page-select-label"
                                id="page-select"
                                value={this.state.selectedFormat}
                                label="Sheet Version"
                                onChange={this.handleSelectFormat}
                            >
                                {this.state.sheets.map((sheet, index) =>
                                    <MenuItem key={index} value={sheet.name}>{sheet.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={8} md={12}  >
                        {this.state.pages.map((page, idx) => (
                            <List key={idx} dense component="div" role="list">
                                <ListItem key={idx}
                                    disablePadding
                                >
                                    <ListItemButton onClick={() => { this.handleToggle(idx) }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt=''
                                                src={page.src}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={page.text} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        ))}
                    </Grid>
                    {this.state.selectedPage &&
                        <Box>
                            <Grid item xs={4} sm={8} md={12}  >
                                <Card sx={{ maxWidth: 400 }} style={{ backgroundColor: 'transparent', width: 400 }}>

                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {'Character Sheet'}
                                        </Typography>
                                        <CardMedia
                                            id={'croptarget'}
                                            component="img"
                                            image={this.state.selectedPage.src}
                                            onLoad={(e) => this.onTargetLoaded(e)}
                                            style={{ position: "relative" }}
                                        />
                                        <LinearProgress value={100} variant={this.state.selectedPage.busy ? 'indeterminate' : 'determinate'} />
                                    </CardContent>
                                    <CardActions >
                                        <Tooltip title="Classify Content">
                                            <IconButton disabled={!this.state.canDoClassify} id={this.state.selectedPage.id + '0'} onClick={this.doBoxes}>
                                                <ExtensionIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Character Class">
                                            <IconButton disabled={!this.state.canDoClassify} id={this.state.selectedPage.id + '0'} onClick={() => { this.changeWidgetType('class') }}>
                                                <PersonIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Character Attribute">
                                            <IconButton disabled={!this.state.canDoClassify} id={this.state.selectedPage.id + '0'} onClick={() => { this.changeWidgetType('attribute') }}>
                                                < FitnessCenterIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Character Skills">
                                            <IconButton disabled={!this.state.canDoClassify} id={this.state.selectedPage.id + '0'} onClick={() => { this.changeWidgetType('skills') }}>
                                                <MedicalServicesIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Remove">
                                            <IconButton disabled={!this.state.canDoClassify} id={this.state.selectedPage.id + '0'} onClick={this.deleteActiveWidget}>
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </CardActions>
                                    <CardActions >
                                        <Tooltip title="Visualize">
                                            <IconButton disabled={!this.state.canDoVision} id={this.state.selectedPage.id + '1'} onClick={this.doVision}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Visualize Only">
                                            <IconButton disabled={true} id={this.state.selectedPage.id + '1'} onClick={this.doVisionOnly}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={this.state.attributes}
                                    columns={this.state.dgColumns}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={this.handleSelectionChanged}
                                    onCellEditCommit={this.handleEditRows}
                                />
                            </div>
                        </Box>
                    }

                </Grid>
            </Box >
        )
    }
}

export default AssetAttrs