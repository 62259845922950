
import React, { Component } from 'react';

import axios from 'axios'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import LinearProgress from '@mui/material/LinearProgress';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import AddFile from './images/add-file.png'
import detectEthereumProvider from '@metamask/detect-provider';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import npc_contract from './images/npc_mint.json'
const Web3 = require('web3')


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class AssetMint extends Component {

    constructor(props) {
        super(props)
        this.state = {
            assetURI: null,
            busy: false,
            avatar_busy: false,
            nftDesc: 'NFT Description',
            nftName: 'Wicked Character',
            assetXSize: 200,
            assetYSize: 250,
            checked: [],
            pages: [],
            outputType: "wm-none",
            outputURI: AddFile,
            avatarURIs:[],
            avatarStep:0,
        };

        this.mint = this.mint.bind(this);
        this.createAvatar = this.createAvatar.bind(this);
        this.createOutput = this.createOutput.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.updateNftName = this.updateNftName.bind(this);
        this.updateNftDesc = this.updateNftDesc.bind(this);

        this.props.pageIDs.forEach(_n => {
            this.state.pages.push({
                id: _n,
                src: this.props.img_ip + '/product/latest/' + this.props.productID + '/' + _n + '/mint',
                text: _n,
                toggled: false,
            });
        });
        this.handleToggle(0); //auto select first
        this.state.pages.push({
            id: '0',
            src: this.props.img_ip + '/product/latest/' + this.props.productID + '/0/avatar',
            text: 'avatar',
            toggled: false,
        })

        console.log('output uri', this.state.pages[0].src);

        this.nameRegex = new RegExp("char.*name", "i");

    }


    handleProductChange(e) {
        console.log('change', e.target.value);
        this.setState({ outputType: e.target.value });
        this.createOutput(e.target.value);
    }
    updateNftName(e) {
        console.log('updating name', e)
        this.setState({ nftName: e.target.value });
    }
    updateNftDesc(e) {
        this.setState({ nftDesc: e.target.value });
    }
    componentDidMount() {
        console.log('mounting');
        if (this.props.productID) {
            console.log('productID provided', this.props.img_ip + this.props.productID)
        }

        this.props.attributesSelected.forEach((_n) => {
            let selectedAttribute = this.props.attributes[_n];
            if (this.nameRegex.test(selectedAttribute.label)) {
                this.setState({ nftName: selectedAttribute.value });
            }
        });
        this.createAvatar();
        this.createOutput(null);
    }

    createAvatar(e) {

        console.log('create avatar');
        this.setState({ avatar_busy: true });
        this.props.onInfoMsg("creating avatar");

        this.props.attributes.forEach((_n) => {
            console.log(_n)
        })
        let selectedAttrs = []
        this.props.attributesSelected.forEach((_n) => {
            selectedAttrs.push(this.props.attributes[_n]);
        });
        console.log("dalle",this.state.selectedAttributes);
        axios.get(this.props.act_ip + '/dalle/prompt', {
            headers: {
                'x-access-token': this.props.token,
            },
            params: {
                productID: this.props.productID,
                selectedAttributes: JSON.stringify(selectedAttrs),
            }
        })
            .then(response => {
                console.log("dalle", response);
                this.props.onInfoMsg("success");
                let avatarURIs = [];
                response.data.assetURIs.forEach(_n => {
                    avatarURIs.push(this.props.img_ip + _n);

                })
                this.setState({
                    busy: false,
                    //avatarURI: this.props.img_ip + response.data.assetURIs[0] + '?' + performance.now() //TODO show all dalle images
                    avatarURIs: avatarURIs,
                });
            }).catch(error => {
                this.props.onErrorMsg('wtf');
            }).finally(() => { this.setState({ avatar_busy: false }) });

    }

    createOutput(e) {

        console.log('create output', this.state.outputType);
        this.setState({ busy: true });
        this.props.onInfoMsg("creating output");

        let pageIDs = [];

        this.state.pages.forEach(_n => {
            if (_n.toggled) {
                pageIDs.push(_n.id);
            }

        })

        let watermark = null;
        let outputType = e
        if (outputType === 'wm-c') { watermark = 'center'; }
        else if (outputType === 'wm-f') { watermark = 'full'; }
        else if (outputType === 'wm-tl') { watermark = 'TL'; }
        else if (outputType === 'wm-tr') { watermark = 'TR'; }
        else if (outputType === 'wm-bl') { watermark = 'BL'; }
        else if (outputType === 'wm-br') { watermark = 'BR'; }
        else if (outputType === 'wm-full') { watermark = 'full'; }


        axios.get(this.props.img_ip + '/artifact', {
            headers: {
                'x-access-token': this.props.token,
            },
            params: {
                productID: this.props.productID,
                pageIDs: JSON.stringify(pageIDs),
                where: watermark,
                scale: .3,
                opacity: .5,
            }
        })
            .then(response => {
                console.log(response);
                this.props.onInfoMsg("success");
                this.setState({
                    outputURI: this.props.img_ip + response.data.assetURI + '?' + Date.now()
                });
            }).catch(error => {
                this.props.onErrorMsg('wtf');
            }).finally(() => { this.setState({ busy: false }) });

    }

    mint(e) {

        this.setState({ busy: true });

        const web3 = new Web3(window.ethereum);
        //let c_addr = '0x8763B64278a85D3CBb024A9742dc2Ab5a4F05D48';
        let c_addr = '0xCBE71CA35F21a2e17f579fda93fD08b77876CA90';
        var contract = new web3.eth.Contract(npc_contract.abi, c_addr);

        this.props.onInfoMsg("minting");
        if (!this.props.attributes) {
            this.props.onErrMsg("no attributes");
            this.setState({ busy: false });
            return;
        }
        let rankingAttributes = [];
        let propertyAttributes = [];
        let statAttributes = [];

        this.props.attributesSelected.forEach((n) => {
            console.log('att', this.props.attributes[n].id, this.props.attributes[n].label, this.props.attributes[n].value)
            let selectedAttribute = this.props.attributes[n];
            if (selectedAttribute.class === "class" || selectedAttribute.class === "name") {
                propertyAttributes.push([
                    selectedAttribute.label,
                    selectedAttribute.value])
            }
            if (selectedAttribute.class === "attr" || selectedAttribute.class === "skills") {
                statAttributes.push([
                    selectedAttribute.label,
                    selectedAttribute.value])
            }
        })
        axios.get(this.props.img_ip + '/mint', {
            headers: {
                'x-access-token': this.props.token,
            },
            params: {
                productID: this.props.productID,
                pageID: this.state.pages[0].id,   //TODO
                name: this.state.nftName,
                description: this.state.nftDesc,
                properties: JSON.stringify(propertyAttributes),
                rankings: JSON.stringify(rankingAttributes),
                stats: JSON.stringify(statAttributes),
            }
        }).then(response => {
            console.log(response);
            //TODO
            //this.onInfoMsg(response.data.hash);

            contract.methods.mintNPC(this.props.account, response.data.meta_uri).send(
                {
                    to: c_addr,
                    from: this.props.account,
                    value: 1000
                }).then((receipt) => {
                    this.props.onInfoMsg("Mint success!");
                    console.log('mint receipt',)
                    this.props.onMint(receipt);
                }).finally(() => { this.setState({ busy: false }) });

        }).catch((error) => {
            console.log(error);
            this.props.onErrorMsg("Unable to pay");

        }).finally(() => { })

    }

    handleToggle(idx) {
        console.log('toggle idx', idx);
        let lpages = this.state.pages;
        lpages[idx].toggled = !this.state.pages[idx].toggled;
        this.setState({ pages: lpages });
        console.log('toggle page ', this.state.pages[idx]);
    };

    render() {
        console.log('rendering');
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" align="center" justify="center" >
                    {this.state.pages.map((page, idx) => (
                        <Grid key={idx} item xs={4} sm={8} md={12}  >
                            <List dense component="div" role="list">
                                <ListItem key={idx}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={() => this.handleToggle(idx)}
                                            checked={this.state.pages[idx].toggled}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt=''
                                                src={page.src}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={page.text} />
                                    </ListItemButton>
                                </ListItem>
                            </List>

                        </Grid>

                    ))}
                    <Grid item xs={4} sm={8} md={6}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {'Dalle'}
                                </Typography>

                                <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                                    <Paper
                                        square
                                        elevation={0}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 50,
                                            pl: 2,
                                            bgcolor: 'background.default',
                                        }}
                                    >
                                    </Paper>
                                    <AutoPlaySwipeableViews
                                        index={this.state.avatarStep}
                                        onChangeIndex={()=>{}}
                                        enableMouseEvents
                                    >
                                        {this.state.avatarURIs.map((step, index) => (
                                            <div key={index}>
                                                {Math.abs(this.state.avatarStep - index) <= 2 ? (
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            height: 255,
                                                            display: 'block',
                                                            maxWidth: 400,
                                                            overflow: 'hidden',
                                                            width: '100%',
                                                        }}
                                                        src={step}
                                                        //alt={step.label}
                                                    />
                                                ) : null}
                                            </div>
                                        ))}
                                    </AutoPlaySwipeableViews>
                                    <MobileStepper
                                        steps={this.state.avatarURIs.length}
                                        position="static"
                                        activeStep={0}
                                        nextButton={
                                            <Button
                                                size="small"
                                                onClick={()=>{console.log("a",this.state.avatarStep);this.setState({avatarStep:this.state.avatarStep+1})}}
                                                disabled={this.state.avatarStep === this.state.avatarURIs.length - 1}
                                            >
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={()=>{console.log("b",this.state.avatarStep);this.setState({avatarStep:this.state.avatarStep - 1});}} disabled={this.state.avatarStep === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </Box>
                                <LinearProgress value={100} variant={this.state.avatar_busy ? 'indeterminate' : 'determinate'} />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={4} sm={8} md={6}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {'NFT'}
                                </Typography>
                                <CardMedia
                                    component="img"
                                    image={this.state.outputURI}
                                />
                                <LinearProgress value={100} variant={this.state.busy ? 'indeterminate' : 'determinate'} />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={4} sm={4} md={6}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 100 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Trait</TableCell>
                                        <TableCell align="right">Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.attributesSelected.map((row, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell > {this.props.attributes[row].label} </TableCell>
                                            <TableCell align="right">{this.props.attributes[row].value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={2} sm={4} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="page-select-label">Output Type</InputLabel>
                            <Select
                                labelId="page-select-label"
                                id="page-select"
                                value={this.state.outputType}
                                label="Output Type"
                                onChange={this.handleProductChange}
                            >
                                <MenuItem value='wm-none'>No Watermark</MenuItem>
                                <MenuItem value='wm-c'>Watermark Center</MenuItem>
                                <MenuItem value='wm-f'>Watermark Full</MenuItem>
                                <MenuItem value='wm-tl'>Watermark Top Left</MenuItem>
                                <MenuItem value='wm-tr'>Watermark Top Right</MenuItem>
                                <MenuItem value='wm-bl'>Watermark Bottom Left</MenuItem>
                                <MenuItem value='wm-br'>Watermark Bottom Right</MenuItem>
                            </Select>

                        </FormControl>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                        <label>NFT Name</label>
                    </Grid>
                    <Grid item xs={10} sm={4} md={6}>
                        <Input className='rw-widget-input' type='text' value={this.state.nftName} onChange={this.updateNftName} />
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                        <label>NFT Description</label>
                    </Grid>
                    <Grid item xs={10} sm={4} md={6}>
                        <Input className='rw-widget-input' type='text' value={this.state.nftDesc} onChange={this.updateNftDesc} />
                    </Grid>

                    <Grid item xs={4} sm={8} md={12} >
                        <Button onClick={this.mint}>Mint</Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default AssetMint
