
import React, { Component } from 'react';
import ReactGA from 'react-ga'

import axios from 'axios'
import AssetSelect from './AssetSelect';
import AssetAttrs from './AssetAttrs';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import AssetMint from './AssetMint';

class AssetApp extends Component {

    constructor(props) {
        super(props)
        this.state = {
            productID: null,
            token: null,
            assetXSize: null,
            assetYSize: null,
            assetURI: null,
            odt_ip: 'https://odt-dot-heph2-338519.uc.r.appspot.com',
            img_ip: 'https://img-dot-heph2-338519.uc.r.appspot.com',
            act_ip: 'https://act-dot-heph2-338519.uc.r.appspot.com',
            busy: false,
            activePane: 0,
            steps: ['Select', 'Attributes', 'Mint'],
            activeStep: -1,
            pages: [],
            attributes: [],
            attributesSelected: [],

        };

        this.nav = this.nav.bind(this);
        this.handleAttrUpdate = this.handleAttrUpdate.bind(this);
        this.handleAttributesSelected = this.handleAttributesSelected.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleReset = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePageSet = this.handlePageSet.bind(this);

    }

    handlePageSet(pageID) {
        if (!this.state.pages.includes(pageID)) {
            this.state.pages.push(pageID);
        }

    }


    componentDidMount() {
        console.log('mounting');

        axios.get(this.state.img_ip + '/asset', {
            params: {
                account: this.props.account
            }
        })
            .then(response => {
                console.log(response);
                this.props.onInfoMsg('Asset ' + response.data.productID, "info");
                this.setState({
                    productID: response.data.productID,
                    token: response.data.token,
                    activeStep: 0
                });
            }).catch(error => {
                this.props.onErrorMsg('Error' + error, "error");
            });
    }

    nav(e, v) {
        console.log(e);
        console.log(v);
        this.setState({ activePane: v });
    }

    handleAttrUpdate(e) {
        console.log('attrs', e)
        this.setState({ attributes: e });
    }
    handleAttributesSelected(e) {
        console.log('attrs', e)
        this.setState({ attributesSelected: e });
    }
    handleBack(e) {
        this.setState({ activeStep: this.state.activeStep - 1 });
    }
    handleNext(e) {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }
    handleReset(e) {
        this.setState({ activeStep: 0 });
    }

    render() {
        console.log('rendering');
        let pane;
        if (this.state.activeStep === 0) {
            console.log('align');
            pane = <AssetSelect
                img_ip={this.state.img_ip}
                odt_ip={this.state.odt_ip}
                act_ip={this.state.act_ip}
                productID={this.state.productID}
                token={this.state.token}
                assetXSize={this.state.assetXSize}
                assetYSize={this.state.assetYSize}
                onErrorMsg={this.props.onErrorMsg}
                onWarnMsg={this.props.onWarnMsg}
                onInfoMsg={this.props.onInfoMsg}
                onPageSet={this.handlePageSet}
                pageIDs={this.state.pages}
            />;
        }

        else if (this.state.activeStep === 1) {
            pane = <AssetAttrs
                img_ip={this.state.img_ip}
                odt_ip={this.state.odt_ip}
                act_ip={this.state.act_ip}
                productID={this.state.productID}
                token={this.state.token}
                attributes={this.state.attributes}
                attributesSelected={this.state.attributesSelected}
                onAttributesReceived={this.handleAttrUpdate}
                onSelection={this.handleAttributesSelected}
                onErrorMsg={this.props.onErrorMsg}
                onWarnMsg={this.props.onWarnMsg}
                onInfoMsg={this.props.onInfoMsg}
                pageIDs={this.state.pages}
            />;
        }
        else if (this.state.activeStep === 2) {
            pane = <AssetMint
                img_ip={this.state.img_ip}
                odt_ip={this.state.odt_ip}
                act_ip={this.state.act_ip}
                productID={this.state.productID}
                token={this.state.token}
                attributes={this.state.attributes}
                attributesSelected={this.state.attributesSelected}
                account={this.props.account}
                chainId={this.props.chainId}
                onErrorMsg={this.props.onErrorMsg}
                onWarnMsg={this.props.onWarnMsg}
                onInfoMsg={this.props.onInfoMsg}
                onMint={this.props.onMint}
                pageIDs={this.state.pages}
            />;
        } else {

            pane = <Skeleton variant="circular" animation="wave" width={400} height={400} />;
        }

        return (
            <Box sx={{ flexGrow: 1 }} >
                <Stepper activeStep={this.state.activeStep}>
                    {this.state.steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {this.state.activeStep === this.state.steps.length ? (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={this.handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={this.state.activeStep === 0}
                                onClick={this.handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            <Button onClick={this.handleNext}>
                                {this.state.activeStep === this.state.steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" align="center" justify="center" >
                    <Grid item xs={4} sm={8} md={12}  >
                        {pane}
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default AssetApp
